<template>
  <form
      id="form"
      action="https://str-ing.us14.list-manage.com/subscribe/post?u=48c50c693a7656495f4f5b6d2&amp;id=cc4cabbcc2"
      method="post"
      class="form">
      <label class="form__label">{{ title }}</label>
      <input
          name="EMAIL"
          placeholder="Enter your e-mail address"
          type="email"
          class="form__input"
      />
  </form>
</template>

<script>
export default {
  name: "Input",
  props: {
    title: String
  }
}
</script>

<style scoped>
.form {
  margin-bottom: 80px;
}
.form__label {
  display: block;
  text-transform: uppercase;
  font-size: 0.625vw;
  line-height: 0.938vw;
  margin-bottom: 4px;
}
.form__input {
  font-family: 'akkurat-mono2';
  border: 0;
  background: none;
  border-bottom: 1px solid #9D9D9D;
  font-size: 0.625vw;
  line-height: 0.938vw;
  color: #9D9D9D;
  width: 100%;
  max-width: 350px;
}
@media screen and (min-width: 1200px) {
  .form {
    margin: 0;
  }
  .form__label {
    font-size: 1.146vw;
    line-height: 1.458vw;
  }
  .form__input {
    font-size: 1.146vw;
    line-height: 1.458vw;
  }
}
</style>
