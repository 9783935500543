var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plans-pricing-page"},[_c('div',[_c('Header',{attrs:{"title":_vm.plansData[_vm.activePanel].title}}),_c('div',{staticClass:"plans-pricing__wrapper"},[_vm._l((_vm.plansData),function(ref,index){
var title = ref.title;
var list = ref.list;
var inputTitle = ref.inputTitle;
return _c('div',{key:index,staticClass:"plans-pricing__list",class:{ 'plans-pricing__list--active': _vm.activePanel === index}},[_c('h2',{staticClass:"plans-pricing__title"},[_vm._v(_vm._s(title))]),_vm._l((list),function(ref,listIndex){
var listTitle = ref.listTitle;
var listText = ref.listText;
return _c('div',{key:listIndex,staticClass:"plans-pricing__item"},[(listTitle)?_c('h3',{staticClass:"plans-pricing__subtitle"},[_vm._v(_vm._s(listTitle))]):_vm._e(),_c('p',{staticClass:"plans-pricing__text"},[_vm._v(_vm._s(listText))])])}),_c('Input',{attrs:{"title":inputTitle}})],2)}),_c('span',{staticClass:"line line--1"}),_c('span',{staticClass:"line line--2"}),_c('div',{staticClass:"tabs"},_vm._l((_vm.tabs),function(tab,index){return (_vm.activePanel !== index)?_c('button',{key:index,staticClass:"tab",on:{"click":function($event){return _vm.handleTabChange(index)}}},[_c('span',[_vm._v(_vm._s(tab))])]):_vm._e()}),0)],2)],1),_c('Navigation',{staticClass:"plans-pricing__navigation"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }