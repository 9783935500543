<template>
  <nav class="navigation">
    <div class="navigation__wrapper">
      <ul class="navigation__list">
        <li
            v-for="({ route, title, link }, index) in navigationLinks"
            :key="index"
            class="navigation__item">
              <router-link v-if="route" :to="link" class="navigation__link">{{ title }}</router-link>
              <a v-else :href="link" class="navigation__link">{{ title }}</a>
        </li>
        <li class="navigation__item navigation__item--logo">
          <img src="@/assets/images/logo.svg" alt="str-ing logo" width="67" height="56">
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  data: () => ({
    navigationLinks: [
      { route: true, title: 'Demo', link: '/video' },
      //{ route: false, title: 'Whitepaper', link: 'https://drive.google.com/file/d/16KFGWZrdGdyzOKSG2embs-NXYokUPblT/view?usp=sharing' },
      { route: true, title: 'Whitepaper', link: '/white-paper' },
      //{ route: false, title: 'Credits', link: 'https://drive.google.com/file/d/1psu0pRjta6RfIDsODwkHuGKOGdSfQkRl/view?usp=sharing' },
      { route: true, title: 'Plans and Pricing', link: '/plans-pricing' }
    ]
  })
}
</script>

<style lang="scss" scoped>
.navigation {
  padding: 20px 15px;
}
.navigation__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.navigation__item {
  font-size: 0.625vw;
  letter-spacing: 0.1px;
  line-height: 0.781vw;

  &--logo {
    display: none;
  }
}
.navigation__link {
  color: #9D9D9D;
}
.navigation__credit {
  display: none;
}
@media screen and (min-width: 1200px) {
  .navigation {
    padding: 20px 35px;
  }
  .navigation__wrapper {
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;
    align-items: center;
  }
  .navigation__list {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .navigation__item {
    margin: 0 43px;
    font-size: 0.938vw;
    line-height: 1.25vw;
    &--logo {
      display: inline-block;
      margin-right: 0;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
  .navigation__credit {
    display: block;
    color: #9D9D9D;
    font-size: 0.938vw;
    line-height: 1.25vw;
  }
}
</style>
