<template>
  <div class="header">
    <router-link class="back-btn" to="/">
      ≤ <span>BACK</span>
    </router-link>
    <h2 class="header__title">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #000000;
  box-shadow: 0 1px 17px rgba(0, 0, 0, 0.2);
  padding: 33px 22px;
  position: relative;
}
.header__title {
  font-family: 'akkurat-mono2';
  text-transform: uppercase;
  font-weight: 400;
  font-size: 22px;
  line-height: 23px;
  margin: 0;
  text-align: center;
}
.back-btn {
  position: absolute;
  font-family: 'akkurat-mono2';
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 1.042vw;
  text-decoration: none;
  color: #000000;
  margin-top: 2px;
  span {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .header {
    box-shadow: none;
    border: 0;
  }
  .header__title {
    display: none;
  }
  .back-btn {
    color: #9D9D9D;
    margin: 0;
    span {
      display: inline-block;
      font-size: 1.146vw;
      line-height: 1.563vw;
      margin-left: -10px;
    }
  }
}
</style>
