<template>
  <div class="plans-pricing-page">
    <div>
      <Header :title="plansData[activePanel].title" />
      <div class="plans-pricing__wrapper">
        <div
            v-for="({ title, list, inputTitle }, index) in plansData"
            :key="index" class="plans-pricing__list"
            :class="{ 'plans-pricing__list--active': activePanel === index}">
          <h2 class="plans-pricing__title">{{ title }}</h2>
          <div
              v-for="({ listTitle, listText }, listIndex) in list"
              :key="listIndex"
              class="plans-pricing__item">
            <h3 v-if="listTitle" class="plans-pricing__subtitle">{{ listTitle }}</h3>
            <p class="plans-pricing__text">{{ listText }}</p>
          </div>
          <Input :title="inputTitle" />
        </div>
        <span class="line line--1"></span>
        <span class="line line--2"></span>

        <div class="tabs">
          <button
              class="tab"
              v-for="(tab, index) in tabs"
              :key="index"
              @click="handleTabChange(index)"
              v-if="activePanel !== index"
          >
            <span>{{ tab }}</span>
          </button>
        </div>
      </div>
    </div>
    <Navigation class="plans-pricing__navigation" />
  </div>
</template>

<script>
import Header from "../components/PlansPricing/Header";
import Navigation from "../components/Navigation";
import Input from "../components/PlansPricing/Input";
export default {
  name: "PlansPricing",
  components: {Input, Navigation, Header},
  data: () => ({
    plansData: [
      {
        title: 'Free plan',
        list: [
          {
            listTitle: 'SUITABLE FOR',
            listText: 'Artists, researchers, architects, designers, performers and writers.'
          },
          {
            listTitle: 'Dedicated access to the Reservoir',
            listText: 'Browse the cultural world and shape it.'
          },
          {
            listTitle: 'LINK WITH PEERS',
            listText: 'Control the trajectory of your work.'
          },
          {
            listText: 'Browse calls for participation, apply seamlessly and earn str-ing.tokens.'
          }
        ],
        inputTitle: 'We’ll keep you posted'
      },
      {
        title: 'Welcome plan',
        list: [
          {
            listTitle: 'SUITABLE FOR',
            listText: 'Curators and editors.'
          },
          {
            listTitle: 'Dedicated access to the Reservoir',
            listText: 'Browse the cultural world and shape it.'
          },
          {
            listTitle: 'CREATE THE CONTEXT',
            listText: 'Link with peers.'
          },
          {
            listText: 'Access to str-ing.org real-time editor and earn str-ing.tokens.'
          }
        ],
        inputTitle: 'We’ll keep you posted'
      },
      {
        title: 'Customised plan',
        list: [
          {
            listTitle: 'SUITABLE FOR',
            listText: 'Institutions and collections.'
          },
          {
            listTitle: 'BUILD YOUR ONLINE MUSEUM',
            listText: 'Display your content and collection.'
          },
          {
            listText: 'Let the world know.'
          }
        ],
        inputTitle: 'Contact us'
      }
    ],
    tabs: [
      'Welcome',
      'Free',
      'Customised'
    ],
    activePanel: 0
  }),
  methods: {
    handleTabChange(index) {
      this.activePanel = index;
    }
  }
}
</script>

<style lang="scss" scoped>
.plans-pricing-page {
  min-height: 100vh;
}
.plans-pricing__wrapper {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 98px);
}
.plans-pricing__list {
  display: none;
  &--active {
    display: block;
  }
}
.plans-pricing__title {
  display: none;
}
.plans-pricing__item:not(:last-child):after {
  content: '—';
  display: block;
  margin-top: 27px;
  margin-bottom: 27px;
}
.plans-pricing__subtitle,
.plans-pricing__text {
  font-family: 'akkurat-mono2';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
.plans-pricing__subtitle {
  color: #9D9D9D;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.tabs {
  display: flex;
  justify-content: space-between;
}
.tab {
  font-family: 'akkurat-mono2';
  background: none;
  border: 0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.677vw;
  line-height: 0.729vw;
  color: #9D9D9D;
  letter-spacing: 0.1em;
  &:first-child:before,
  &:last-child:after {
    content: '≥';
    margin: 0 6px;
  }
  &:first-child:before {
    display: inline-block;
    transform: rotate(-180deg);
  }
}
.plans-pricing__navigation {
  display: none;
}
@media screen and (min-width: 1200px) {
  .plans-pricing-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .plans-pricing__wrapper {
    padding: 30px 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 12px;
    height: 100%;

  }
  .plans-pricing__list {
    display: block;
    position: relative;
    &:nth-child(1) {
      grid-column: 2/5;
    }
    &:nth-child(2) {
      grid-column: 6/9;
    }
    &:nth-child(3) {
      grid-column: 10/13;
    }
  }
  .line {
    display: block;
    width: 1px;
    height: 100%;
    background: #9D9D9D;
    grid-row: 1;
    margin: auto;
    &--1 {
      grid-column: 5/6;
    }
    &--2 {
      grid-column: 9/10;
    }
  }
  .plans-pricing__title {
    display: block;
    font-weight: 400;
    font-size: 1.146vw;
    line-height: 1.458vw;
    text-transform: uppercase;
    &:after {
      content: '—';
      display: block;
      margin-bottom: 20px;
    }
  }
  .plans-pricing__subtitle,
  .plans-pricing__text {
    font-size: 1.042vw;
    line-height: 1.458vw;
  }
  .tabs {
    display: none;
  }
  .plans-pricing__navigation {
    display: unset;
  }
}
</style>
